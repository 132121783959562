import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import PublicIcon from '@mui/icons-material/Public';
import {MapEntity, Variable} from './types/commonTypes';

export const GEOSERVER_BASE_URL = process.env.GEOSERVER_BASE_URL;
export const GEOSERVER_DISTRICT_LAYER = 'irriter:district';
export const GEOSERVER_SECTOR_LAYER = 'irriter:districtsector';
export const GEOSERVER_LANDPARCEL_LAYER = 'irriter:landparcel';

const GEOSERVER_ENTITY_LAYER: Record<MapEntity, string> = {
  [MapEntity.LANDPARCEL]: GEOSERVER_LANDPARCEL_LAYER,
  [MapEntity.SECTOR]: GEOSERVER_SECTOR_LAYER,
  [MapEntity.DISTRICT]: GEOSERVER_DISTRICT_LAYER
};

export const GEOSERVER_TILES_URL = (mapEntity: MapEntity) => `${GEOSERVER_BASE_URL}/gwc/service/tms/1.0.0/${GEOSERVER_ENTITY_LAYER[mapEntity]}@MVT@pbf/{z}/{x}/{-y}.pbf`;
export const GEOSERVER_WFS_URL = (mapEntity: MapEntity) => `${GEOSERVER_BASE_URL}/ows?service=wfs&version=2.0.0&request=GetFeature&typeNames=${GEOSERVER_ENTITY_LAYER[mapEntity]}&outputFormat=application/json`;

export const DRAWER_WIDTH = 300;
export const MINI_SIDE_PANEL_WIDTH = 61;
export const MINI_SIDE_PANEL_DENSE_WIDTH = 45;
export const FOOTER_HEIGHT = 30;

export const MINISIDEPANEL_CONFIG = [{
  id: 'mapView',
  route: '../map',
  label: 'map',
  content: <MapIcon/>,
  isNavigable: true
}, {
  id: 'landparcelView',
  label: 'landparcel',
  content: <TableChartIcon/>,
  isNavigable: false
}, {
  id: 'geoservicesView',
  route: '../geoservices',
  label: 'geoservices',
  content: <PublicIcon/>,
  isNavigable: true
}];

export const MAPSTYLES = [{
  id: 'positron',
  thumbnail: 'basemap-thumbnails/icgc_mapa_base_gris.png',
  url: 'https://geoserveis.icgc.cat/contextmaps/icgc_mapa_base_gris.json',
  firstTopLayer: 'place_other'
}, {
  id: 'fulldark',
  thumbnail: 'basemap-thumbnails/icgc_mapa_base_fosc.png',
  url: 'https://geoserveis.icgc.cat/contextmaps/icgc_mapa_base_fosc.json',
  firstTopLayer: 'place-other'
}, {
  id: 'osm-bright',
  thumbnail: 'basemap-thumbnails/icgc_delimitacio_estandard.png',
  url: 'https://geoserveis.icgc.cat/contextmaps/icgc_delimitacio_estandard.json',
  firstTopLayer: 'place-other'
}, {
  id: 'hibrid',
  thumbnail: 'basemap-thumbnails/icgc_orto_estandard.png',
  url: 'https://geoserveis.icgc.cat/contextmaps/icgc_orto_estandard.json',
  firstTopLayer: 'place-other'
}];

export const INITIAL_MAPSTYLE_URL = MAPSTYLES[3].id;

export const LANDPARCEL_VARIABLES: { [index: string]: Variable } = {
  'current-et': {
    apiFieldName: 'et',
    domain: [0, 8],
    colorScheme: 'BrewerRdYlBu8',
    units: 'mm/day',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'accum-et': {
    apiFieldName: 'accum_et',
    domain: [0, 1100],
    colorScheme: 'BrewerRdYlBu11',
    units: 'mm',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: true,
  },

  'current-irr': {
    apiFieldName: 'irr',
    domain: [0, 16],
    colorScheme: 'BrewerRdYlBu8',
    units: 'mm/day',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'accum-irr': {
    apiFieldName: 'accum_irr',
    domain: [0, 1400],
    colorScheme: 'BrewerRdYlBu7',
    units: 'mm',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: true,
  },

  'accum_dr': {
    apiFieldName: 'accum_dr',
    domain: [0, 1400],
    colorScheme: 'BrewerRdYlBu7',
    units: 'mm',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: true,
  },
  'p50_pending-dr': {
    apiFieldName: 'p50_pending_dr',
    domain: [0, 500],
    colorScheme: 'BrewerRdYlBu10',
    units: 'mm',
    isMain: false,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'p75_pending-irr': {
    apiFieldName: 'p75_pending_irr',
    domain: [0, 1400],
    colorScheme: 'BrewerRdYlBu7',
    units: 'mm',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'p75_pending-et': {
    apiFieldName: 'p75_pending_et',
    domain: [0, 1100],
    colorScheme: 'BrewerRdYlBu11',
    units: 'mm',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'fapar': {
    apiFieldName: 'fapar',
    domain: [0, 1],
    colorScheme: 'BrewerRdYlGn10',
    units: '',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'fapar_all': {
    apiFieldName: 'fapar_all',
    domain: [0, 1],
    colorScheme: 'BrewerRdYlGn10',
    units: '',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  't_crop': {
    apiFieldName: 't_crop',
    domain: [0, 10],
    colorScheme: 'BrewerRdYlBu10',
    units: 'mm/day',
    isMain: false,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'asw': {
    apiFieldName: 'asw',
    domain: [0, 200],
    colorScheme: 'BrewerRdYlBu10',
    units: 'mm',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'fpotyield': {
    apiFieldName: 'fpotyield',
    domain: [0, 1],
    colorScheme: 'BrewerRdYlBu10',
    units: '',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'cwsi': {
    apiFieldName: 'cwsi',
    domain: [0, 1],
    colorScheme: 'BrewerRdYlBu10',
    reverseColor: true,
    units: '',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  }
};

export const SECTOR_VARIABLES: { [index: string]: Variable } = {
  'current-et': {
    apiFieldName: 'et',
    domain: [0, 80],
    colorScheme: 'BrewerRdYlBu8',
    units: 'hm3/day',
    mapUnits: 'm3/ha/day',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'current-irr': {
    apiFieldName: 'irr',
    domain: [0, 160],
    colorScheme: 'BrewerRdYlBu8',
    units: 'hm3/day',
    mapUnits: 'm3/ha/day',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'current-watercatch': {
    apiFieldName: 'watercatch',
    domain: [0, 160],
    colorScheme: 'BrewerRdYlBu8',
    units: 'hm3/day',
    mapUnits: 'm3/ha/day',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
  },
  'accum-et': {
    apiFieldName: 'accum_et',
    domain: [0, 11000],
    colorScheme: 'BrewerRdYlBu11',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'accum-irr': {
    apiFieldName: 'accum_irr',
    domain: [0, 14000],
    colorScheme: 'BrewerRdYlBu7',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: true,
  },
  'accum-watercatch': {
    apiFieldName: 'accum_watercatch',
    domain: [0, 14000],
    colorScheme: 'BrewerRdYlBu7',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: true,
    hasState: true,
    hasForecast: true,
  },
  'p75_pending-et': {
    apiFieldName: 'p75_pending_et',
    domain: [0, 11000],
    colorScheme: 'BrewerRdYlBu11',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'p75_pending-irr': {
    apiFieldName: 'p75_pending_irr',
    domain: [0, 14000],
    colorScheme: 'BrewerRdYlBu7',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  },
  'p75_pending-watercatch': {
    apiFieldName: 'p75_pending_watercatch',
    domain: [0, 11000],
    colorScheme: 'BrewerRdYlBu11',
    units: 'hm3',
    mapUnits: 'm3/ha',
    isMain: true,
    hasRecord: false,
    hasState: true,
    hasForecast: false,
  }
};

export const RASTER_VARIABLES: { [index: string]: Variable } = {
  'raster-et': {
    apiFieldName: 'raster:et',
    domain: [0, 8],
    colorScheme: 'BrewerRdYlBu8',
    units: 'mm/day',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
    isRaster: true,
  },
  'raster-vigor': {
    apiFieldName: 'raster:vigor',
    domain: [0, 1],
    colorScheme: 'BrewerRdYlGn10',
    units: '',
    isMain: false,
    hasRecord: true,
    hasState: true,
    hasForecast: false,
    isRaster: true,
  }
};

export const INITIAL_SELECTED_VARIABLE = 'current-irr';

export const SECTOR_MIN_ZOOM = 11;
export const LANDPARCEL_MIN_ZOOM = 13;

export const API_BASE_URL = process.env.API_BASE_URL;
