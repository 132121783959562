import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {FOOTER_HEIGHT} from '../config';
import {Lang} from '../types/commonTypes';
import LegalText from './LegalText';

const Footer: FC = () => {
  const {t, i18n} = useTranslation();
  const lang: Lang = i18n.resolvedLanguage as Lang;

  return <Box sx={{bgcolor: 'primary.main', width: '100%', height: FOOTER_HEIGHT, position: 'absolute', bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
    <LegalText linkLabel={t('legalNotice')} markdownUrl={`legal/legalNotice_${lang}.md`} />
    <LegalText linkLabel={t('privacyPolicy')} markdownUrl={`legal/privacyPolicy_${lang}.md`} />
  </Box>;
};

export default Footer;
