import React, {FC, useState} from 'react';
import useFetch from '../hooks/useFetch';
import Typography from '@mui/material/Typography';
import {Dialog, DialogContent} from '@mui/material';
import Markdown from 'markdown-to-jsx';

const linkSx = {
  color: 'white',
  textAlign: 'center',
  mr: 2,
  cursor: 'pointer'
};

const paperProps = {
  sx: {
    height: 'auto',
    maxHeight: '70vh',
    pb: 1,
    width: '400px',
    maxWidth: 'none',
    ml: '400px'
  }
};

const dialogContentSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

type LegalTextProps = {
  linkLabel: string,
  markdownUrl: string
}

const LegalText: FC<LegalTextProps> = ({linkLabel, markdownUrl}) => {
  const {data: markdownContent} = useFetch<string>(markdownUrl, undefined, true, false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  return markdownContent ? <>
    <Typography variant='caption' sx={linkSx} onClick={() => setDialogOpen(true)}>{linkLabel}</Typography>
    <Dialog open={isDialogOpen} fullWidth PaperProps={paperProps} onClose={() => setDialogOpen(false)} sx={{ '& .MuiDialog-paper': { width: '70vw' } }}>
      <DialogContent sx={dialogContentSx}>
        <Markdown>{markdownContent}</Markdown>
      </DialogContent>
    </Dialog>
  </> : null;
};

export default LegalText;
